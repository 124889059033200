var Nothing, ReactView;

import Chaplin from 'chaplin';

import React from 'react';

import {
  createRoot
} from 'react-dom/client';

import {
  Provider
} from 'react-redux';

import {
  NotificationsContext,
  TransitionOverridesContext
} from '@lookout/ui';

import {
  merge,
  parseQuery
} from '../../lib/utils';

import RouteContext, {
  EMPTY_ROUTE
} from '../../components/base/route-context';

Nothing = function({children}) {
  return children;
};

export default ReactView = (function() {
  class ReactView extends Chaplin.View {
    initialize(options) {
      super.initialize(...arguments);
      if (options.el) {
        this.keepElement = true;
      }
      this.component = options.component;
      this.props = options.props;
      this.notificationsContext = options.notificationsContext;
      // using transitionDisable: true proved to break scenarios where a render of focused button is expected
      return this.transitionsContext = {
        transitionDuration: global.Cypress ? '1ms' : void 0
      };
    }

    attach() {
      super.attach(...arguments);
      this.root = createRoot(this.el);
      return this.renderComponent();
    }

    renderComponent() {
      var ref;
      if ((ref = this.root) != null) {
        ref.render(this.wrapComponent());
      }
      return null;
    }

    unmountComponent() {
      var ref;
      return (ref = this.root) != null ? ref.unmount() : void 0;
    }

    // Updates React Component props using `merge` algorithm
    setProps(props) {
      this.props = merge({}, this.props, props);
      return this.renderComponent();
    }

    // Updates React Component props using `extend` algorithm
    resetProps(props) {
      this.props = {...this.props, ...props};
      return this.renderComponent();
    }

    setRoute(route) {
      this.route = route;
      return this.renderComponent();
    }

    wrapComponent() {
      var Component, StoreProvider, store;
      ({store} = Chaplin.mediator);
      StoreProvider = store ? Provider : Nothing;
      Component = this.component;
      return <StoreProvider store={store}>
      <RouteContext.Provider value={this.route}>
        <NotificationsContext.Provider value={this.notificationsContext}>
          <TransitionOverridesContext.Provider value={this.transitionsContext}>
            <Component {...this.props} />
          </TransitionOverridesContext.Provider>
        </NotificationsContext.Provider>
      </RouteContext.Provider>
    </StoreProvider>;
    }

    render() {
      return this;
    }

    dispose() {
      if (!this.disposed) {
        this.unmountComponent();
        delete this.props;
        delete this.component;
      }
      return super.dispose(...arguments);
    }

  };

  ReactView.prototype.autoRender = true;

  ReactView.prototype.route = EMPTY_ROUTE;

  ReactView.prototype.optionNames = Chaplin.View.prototype.optionNames.concat(['el', 'route']);

  return ReactView;

}).call(this);
