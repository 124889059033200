import {createRoot} from 'react-dom/client'
import {Global} from '@emotion/react'
import {colors, globalStyles} from '@lookout/ui'
import {createReactContainer} from '../lib/utils'
import printStyles from './theme/print-styles'

let cypressStyleOverrides

/* eslint-disable spaced-comment */
///////////////
/////////////////////
///////////////////////////
///////////
////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////

///////////////////////////////
///////////////////////////////////////////////////////
//////////////////////////
//////////

//////////////////////////////////////////////////
////////////////////////////////////////////////////
/////////////////////////////////////
//////////

////////////////////////////
/////////////////////////////////////
//////////

//////////////////////////////////////////////
//////////////////////////////////////////
//////////

///////////////////////////////////////
///////////////////////////////
//////////

///////////////////////////
/////////////////////////////
//////////

/////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////
///////////////////////////////////
///////////////////////////////////////////////////////////////////
/////////////////////////////////
/////////////////////////
///////////////////////////////////////////////
////////////////////////
////////////////////////////
//////////////////////
////////////////////////////////
/////////////////////
/////////////////////////
/////////////////////////
//////////////
////////////////////////////////////////////
////////////

//////////////////////////////////////////
//////////////////////
////////////////////////////////////////////
/////////////////////
////////////

//////////////////////////////////////////////
////////////////////////////////////////////
////////////
//////////
////////
//////
///
 
//////////
/* eslint-enable spaced-comment */

// HACK: A special enforcement of adding lookout-ui global EmotionCSS styles
// with all CSS color vars etc into the page before all other UI is rendered
// TODO: To remove this once MES console is at 100% React markup, so we add
// Global component as part of default React app layout
export const initGlobalStyles = ({rootId} = {}) => {
  const {container} = createReactContainer({
    className: 'lookout-global-styles',
    rootId,
  })
  const reactRoot = createRoot(container)
  reactRoot.render(
    <>
      <Global styles={[globalStyles, printStyles]} />
      <Global styles={cypressStyleOverrides} />
    </>
  )
}
