import {
  tableFnStyles,
  tableStyleConstants,
  colors,
  MODAL_Z_INDEX,
} from '@lookout/ui'
import {breakpoints} from '@lookout/design'
import {mediaPrint} from './print-styles'

const {
  aboveQuery,
  belowQuery,
  withinQuery,
  screenXsMax,
  screenSmMax,
  screenSmMin,
  screenMdMax,
  screenMdMin,
  screenLgMin,
} = breakpoints

export const contentPadding = 40
export const mobileContentPadding = contentPadding / 2

// A rip-off of Bootstrap CSS .table-responsible
const tableResponsiveFn = ({max = screenXsMax, noWrap = true} = {}) => ({
  overflowX: 'auto',
  minHeight: '0.01%',

  [belowQuery({max})]: {
    width: 'auto',
    overflowY: 'hidden',

    table: {
      marginBottom: contentPadding / 2,

      ...(noWrap && {
        '> thead > tr > th': {
          whiteSpace: 'nowrap',
        },

        '> tbody > tr > td': {
          whiteSpace: 'nowrap',
        },
      }),
    },
  },
})

export const containerStyles = {
  // a default style for root level page container elements (including top and bottom gaps)
  content: {
    padding: contentPadding,

    [belowQuery({max: screenXsMax})]: {
      padding: mobileContentPadding,
    },
  },

  // a special style for root level page container that stack other top level components (with their own top-bottom gaps)
  contentStack: {
    paddingLeft: contentPadding,
    paddingRight: contentPadding,

    [belowQuery({max: screenXsMax})]: {
      paddingLeft: mobileContentPadding,
      paddingRight: mobileContentPadding,
    },

    ...mediaPrint({
      paddingLeft: mobileContentPadding,
      paddingRight: mobileContentPadding,
    }),
  },

  // a tool style to expand the page component back to the original page container viewport (to beat those edge gaps)
  fullWidth: {
    marginLeft: -contentPadding,
    marginRight: -contentPadding,

    [belowQuery({max: screenXsMax})]: {
      marginLeft: -mobileContentPadding,
      marginRight: -mobileContentPadding,
    },
  },

  tableResponsive: tableResponsiveFn({max: screenSmMax}),
  tableResponsiveFn,

  // based on layout.styl: .sticky
  sticky: ({to = 'top', dropShadow = true}) => ({
    backgroundColor: colors.white,
    ...(dropShadow && {
      boxShadow: `0 ${to === 'top' ? 1 : -1}px 2px ${colors.gray100}`,
    }),
    margin: `-${contentPadding / 2}px -${contentPadding}px`,
    padding: `${contentPadding / 2}px ${contentPadding}px`,
    position: 'sticky',
    zIndex: MODAL_Z_INDEX - 10,
    [to]: 0,

    [belowQuery({max: screenSmMin})]: {
      margin: `-${mobileContentPadding / 2}px -${mobileContentPadding}px`,
      padding: `${mobileContentPadding / 2}px ${mobileContentPadding}px`,
    },
  }),
}

const cellPaddingStyle = {
  ':first-of-type': {
    paddingLeft: contentPadding,
  },
  ':last-of-type': {
    paddingRight: contentPadding,
  },
  [belowQuery({max: screenXsMax})]: {
    ':first-of-type': {
      paddingLeft: mobileContentPadding,
    },
    ':last-of-type': {
      paddingRight: mobileContentPadding,
    },
  },
}

export const tableLayoutStyles = {
  fullWidth: {
    width: '100%',
    marginBottom: contentPadding / 2,

    '> thead': {
      '> tr': {
        '> th': cellPaddingStyle,
      },
    },

    '> tbody': {
      '> tr': {
        '> td': {
          ...cellPaddingStyle,

          '&.row-actions': {
            paddingRight: contentPadding / 2,

            [belowQuery({max: screenXsMax})]: {
              paddingRight: mobileContentPadding / 2,
            },
          },
        },
      },
    },
  },
  explorer: {
    '> tbody': {
      '> tr': {
        ':focus': {outlineWidth: 2},
        '> td': {
          height: 45, // min-height for single text line rows, to visually balance 2 lines rows
          '> a': {
            // this normally applies to all row navigation links and
            // it helps those links to take the full cell width
            display: 'block',
          },
        },
      },
    },
  },
  expandable: {
    ...tableFnStyles.expandable({
      cellHorizontalPadding: contentPadding,
      thumbStyle: {
        [belowQuery({max: screenXsMax})]: {
          paddingLeft:
            mobileContentPadding - tableStyleConstants.expandThumbWidth,
        },
      },
    }),
  },
}

export const detailsSeparatorStyle = {
  ':not(:last-of-type)::after': {content: '" · "'},
}

export const loadingStyle = {
  '&.loading.in': {
    opacity: 0.5,
    pointerEvents: 'none',
  },
}

export const colStyles = ({size = 'xs', num = 12}) => {
  const widthPercentage = `${(num / 12) * 100}%`

  const mediaQueries = {
    xs: belowQuery({max: screenXsMax}),
    sm: aboveQuery({min: screenSmMin}),
    md: aboveQuery({min: screenMdMin}),
    lg: aboveQuery({min: screenLgMin}),
  }

  return {
    flex: `0 0 100%`,
    width: '100%',
    paddingLeft: 15,
    paddingRight: 15,
    minHeight: 1,
    [mediaQueries[size]]: {
      flex: `0 0 ${widthPercentage}`,
      width: widthPercentage,
    },
  }
}

export const hiddenLg = {
  [aboveQuery({min: screenLgMin})]: {
    display: 'none !important',
  },
}

export const hiddenMd = {
  [withinQuery({min: screenMdMin, max: screenMdMax})]: {
    display: 'none !important',
  },
}

export const hiddenSm = {
  [withinQuery({min: screenSmMin, max: screenSmMax})]: {
    display: 'none !important',
  },
}

export const hiddenXs = {
  [belowQuery({max: screenXsMax})]: {
    display: 'none !important',
  },
}

export const expandableRowStyle = {
  '&.expandable.expanded .lookout-badge, &.expandable:not(.no-hover):hover .lookout-badge':
    {
      color: colors.primary,
      backgroundColor: colors.lightGreen200,
    },
}
