import 'core-js/stable'
import './favicons'
import './styles/all'
import {isNetworkError} from '@lookout/request'
import {
  loadConfiguration,
  hideAppLoadingProgress,
  loadTranslations,
  logEverythingIsOkay,
} from './lib/init-helper'
import {initGlobalStyles} from './styles/init-global-styles'
import {createApp} from './lib/init-legacy-helper'
import {initChangeTracker} from './lib/change-tracker'
import {handleError} from './lib/error-helper'
import errorViewHelper from './views/errors/error-view-helper'
import {getLocation, replaceLocation, replaceState} from './lib/location-proxy'
import {parseQuery, stringifyQuery, urlJoin, guidPattern} from './lib/utils'
/* eslint-disable import/order, spaced-comment */
///////////////
/////////////////////////////////////////
///////////////////////////////////////////////////////
//////////
/* eslint-enable spaced-comment */

const showNoAccessError = () =>
  errorViewHelper.noAccess({container: '#root', showLogout: true})

const showGenericError = () =>
  errorViewHelper.genericError({container: '#root'})

const isMockDataEnt = () => {
  const [, entGuid] =
    getLocation().pathname.match(new RegExp(`^/(${guidPattern})`, 'i')) || []
  return entGuid === '11111111-1111-1111-1111-111111111111' // first tenancy Ent in cache.json
}

const maybeRedirectToFeatureBranchEnv = () => {
  // If this is a feature branch URL that has a nested path, we need to redirect
  // browser to the URL that only has feature branch name as it will be
  // properly resolved by CloudFront to the special MES Console app instance
  const [, urlRoot, path] =
    getLocation().pathname.match(/^(\/features\/[a-z0-9-_]+)(\/.*)/i) || []
  if (path) {
    // We pass deepLinkPath and deepLinkQuery as GET params to be restored by
    // the feature branch app instance
    replaceLocation(
      `${urlRoot}?${stringifyQuery({
        deepLinkPath: path,
        deepLinkQuery: getLocation().search || null,
      })}`
    )
    return
  }

  // If these params are present, the page request is likely a result of redirect
  // from the main MES app instance to the feature branch app instance.
  const {deepLinkPath, deepLinkQuery} = parseQuery(getLocation().search)
  if (deepLinkPath || deepLinkQuery) {
    replaceState(
      {},
      window.document.title,
      urlJoin(getLocation().pathname, deepLinkPath, deepLinkQuery)
    )
  }
}

const main = async () => {
  try {
    maybeRedirectToFeatureBranchEnv()
    initGlobalStyles()
    await loadConfiguration({options: {skipErrorHandling: true}}) // to skip global error handling
    await loadTranslations()
    initChangeTracker()
    createApp()
    logEverythingIsOkay()
  } catch (error) {
    hideAppLoadingProgress()
    if (isNetworkError(error)) {
      // try handle generic errors like 401 or 406 using global error handler
      if (!handleError(error.response.status, error.body)) {
        if (error.response.status === 403) {
          showNoAccessError()
        } else {
          showGenericError()
        }
      }
    } else {
      showGenericError()
      throw error // for logging into Sentry
    }
  }
}

///////////////
////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
/////////////////////////////
////////////////////////////////////////////////////

///////////////////////////////
/////////////////////////////////
/////////////////////////////////////////////////////////
/////////////////
//////////
//////////////////
//////////////////
/////////////////////
////////////////////////////////////////////////
////////////////////
//////////////////
////////////////////////////////////
////////////////////////////
////////////////////
//////////////////////////////
//////////////////////////////////
//////
///

////////////////////////////////////////
///////////////////////////////////////
/////////////////////////////////////////
////////////
//////////////////////////////
////////
///////////////////
/////
///

/////////////////////////////////
//////////////////////////////////////
////////////////
/////////////////////
//////////////////////////
////////////////
////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////
/////////////////////////////////////
/////////////////////////////////////////
///////////////////////////////////////////

/////////////////////////////////////////////////////////////////
///////////////////////////////////
////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////
/////////////////////
/////////////
///////////////////////////////
///////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////
////////////
//////////
////////
//////
//////////
/////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////
///
 
//////////

main()
